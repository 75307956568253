import React from "react"
import Layout from "../components/layout"

const About = () => {
  return (
    <Layout>
      <h1>About Page</h1>
      <p>This is the page about me</p>
    </Layout>
  )
}

export default About
